@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.root {
  display: flex;
  flex-direction: column;
  gap: 1rem 0.5rem;
  inline-size: 100%;
  margin: 0 auto;
}

.fileUploader {
  display: flex;
  flex-wrap: nowrap;
  gap: 0.75rem;
  padding: 1.25rem 1rem;
  border: 2px dashed var(--neutral-200);
  border-radius: 1rem;
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-300);
  cursor: pointer;
  position: relative;
  transition: all var(--transition-normal);

  @media (min-width: 640px) {
    padding: 1.875rem 1.5rem;
  }

  & input[type='file'] {
    display: none;
  }

  & label {
    cursor: pointer;
  }
}

.dragOver {
  border-color: var(--neutral-400);
  color: var(--neutral-400);
  background-color: var(--neutral-white);
}

.filesContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.hasFiles {
  border: 0;
  color: var(--neutral-400);
  background-color: var(--neutral-white);
}

.isLoading {
  border: 1px solid var(--neutral-400);
  color: var(--neutral-300);
  background-color: var(--neutral-white);
  pointer-events: none;
}

.removeButton {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  padding: 0;
  border: 0;
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-400);
  background-color: transparent;
  cursor: pointer;
}

.icon {
  margin-inline-start: auto;
  color: var(--status-success);
}

.fileName {
  width: min(55vw, 26.25rem);
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

div:has(input[type='file']) {
  outline: 2px solid transparent;
  outline-offset: 2px;
  transition: all var(--transition-normal);

  &:focus-visible {
    outline-color: var(--brand-400-active);
  }
}

.loader {
  margin-inline-start: auto;
  color: var(--brand-400);
  animation: spin 3s linear infinite;
}

.rejectedFilesList ul {
  margin-block: 0;
  padding-inline-start: 0;
  list-style-type: none;
}
