.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: var(--space-m);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: var(--space-xl);
  width: min(100%, 40rem);
  padding: var(--space-l);
  border-radius: var(--space-l);
  background-color: var(--neutral-white);
}

.header {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-xs);
}

.title {
  font: var(--font-xl);
  text-align: center;
}

.description {
  font: var(--font-m);
  text-align: center;
}

.body {
}
