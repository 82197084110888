.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.fileUploader {
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
}

.sendButton {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.sendButton:hover {
  background-color: #45a049;
}

.success {
  color: green;
  margin-top: 10px;
  text-align: center;
  width: 100%;
  max-width: 400px;
}