.root {
  position: relative;
}
.image {
  width: 100%;
  height: 100%;
  min-height: 33.75rem;
  object-fit: cover;
  object-position: center;
  border-radius: 1.5rem;
}
@media (min-width: 1024px) {
  .image {
    min-height: 35.5rem;
  }
}
.boxTexts {
  position: absolute;
  top: -1rem;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--neutral-white);
}
.textIcons {
  background-color: rgba(243, 246, 246, 0.08);
  backdrop-filter: blur(10px);
  padding: 0.5rem 1.8rem;
  border-radius: 3em;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.textIcons span {
  font-size: 0.75rem;
  font-weight: 600;
}
.boxTexts h1 {
  max-width: 90%;
  padding: 2rem 1rem 2.5rem;
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
  line-height: 1.2;
  letter-spacing: -1px;
}
@media (min-width: 768px) {
  .boxTexts h1 {
    max-width: 80%;
    font-size: 3rem;
    line-height: 1;
  }
}
@media (min-width: 1024px) {
  .boxTexts h1 {
    max-width: 53.125rem;
    font-size: 4rem;
    letter-spacing: -3px;
  }
}
.button {
  padding-inline: 1.81rem;
}
