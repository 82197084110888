.root {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-l);
  padding: 0.75rem;
  position: relative;
  border-radius: 1rem;
  border: 1px solid var(--brand-300);
  background-color: var(--brand-200);

  @media (min-width: 768px) {
    padding: 1.25rem;
  }
}

.header {
}

.content {
  display: none;

  inline-size: calc(100% - 1rem);
  background-color: var(--brand-200);
}

.content.isOpen {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-l);
}

.selector {
  display: flex;
  column-gap: 1rem;
  align-items: center;
  inline-size: 100%;
  padding: 0;
  border: none;
  text-decoration: none;
  background-color: transparent;
  position: relative;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    inset: -0.5rem;
    z-index: 0;
    background-color: var(--brand-200);
    border-radius: 1rem;
    transition: background-color var(--transition-normal) ease-in-out;
  }

  &:hover,
  &:focus-visible {
    &::after {
      background-color: var(--brand-300);
    }
  }

  @media (min-width: 768px) {
    column-gap: 1.5rem;
  }
}

.selector > * {
  position: relative;
  z-index: 1;
}

.selector[disabled] {
  pointer-events: none;
  cursor: default;
}

.selector .isOpen .icon {
  transform: translateY(-50%) rotate(-180deg);
}

.info {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: var(--space-xxs);
  text-align: left;
}

.info > strong {
  inline-size: calc(100% - 2rem);
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-400);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 420px) {
    inline-size: auto;
  }
  @media (min-width: 1024px) {
    font: var(--font-l);
    letter-spacing: var(--font-l-letter-spacing);
  }
}

.info > span {
  display: none;

  @media (min-width: 768px) {
    display: block;
    font: var(--font-m);
    letter-spacing: var(--font-m-letter-spacing);
    color: var(--neutral-300);
  }
}

.icon {
  position: absolute;
  inset-block-start: 50%;
  inset-inline-end: 0;
  transform: translateY(-50%);
  transition: transform var(--transition-normal) ease-in-out;
}
