/*
 * Due to the integration of the Cookie Consent library, which imposes
 * certain styling constraints and specificity challenges, we are compelled
 * to use `!important` and other non-standard CSS practices. This approach
 * ensures our cookie consent UI elements are displayed correctly and
 * consistently across all browsers, overriding the library's default styles
 * when necessary. Our goal is to maintain the integrity and usability of the
 * consent interface, even though this means deviating from best CSS practices.
 * This decision is made with careful consideration of the overall user experience
 * and legal compliance requirements.
 */

.freeprivacypolicy-com---reset {
  font-family: var(--font-family-sans-serif) !important;
}

.freeprivacypolicy-com---nb-simple {
  max-width: 100% !important;
}

.freeprivacypolicy-com---palette-light :is(.cc-nb-okagree, .cc-nb-reject, .cc-cp-foot-save) {
  background-color: var(--brand-400) !important;
}

.cc-cp-body-tabs {
  flex-direction: column!important;
}
.cc-cp-foot-save, .cc-nb-main-container button {
  border-radius: 1.25rem !important;
}