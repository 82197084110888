.root {
  display: flex;
  flex-direction: column;
  gap: var(--space-s);

  @media (min-width: 1280px) {
    flex-direction: row;
    column-gap: var(--space-2xl);
  }
}

.item {
  display: flex;
  flex-direction: column;
}

.itemDismiss {
  color: var(--neutral-200);
}

.value {
  font: var(--font-l);
  letter-spacing: var(--font-l-letter-spacing);

  &:not(.itemDismiss > &) {
    color: var(--neutral-400);
  }

  @media (min-width: 1024px) {
    font: var(--font-xl-bold);
    letter-spacing: var(--font-xl-bold-letter-spacing);
  }
}

.label {
  font: var(--font-s);
  letter-spacing: var(--font-s-letter-spacing);
  text-transform: capitalize;

  @media (min-width: 1024px) {
    font: var(--font-m);
    letter-spacing: var(--font-m-letter-spacing);
  }
}
