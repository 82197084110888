.root {
  min-inline-size: 18rem;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  row-gap: var(--space-m);
  inline-size: 100%;
  padding: 1rem;
  border: 0;
  border-radius: 1.25rem;
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-300);
  background-color: var(--brand-200);
  transition: all var(--transition-normal);
  outline: 2px solid transparent;
  outline-offset: -2px;
  cursor: pointer;

  &:not(:disabled):hover,
  &:not(:disabled):focus-visible {
    outline-color: var(--brand-300);

    & .icon {
      box-shadow: 8px 8px 11.17px -5.58px #a6b4c891, 0px 0px 56.77px 0px #ffffff87,
        -20px 20px 85.63px -29.78px #a6b4c873;
    }
  }

  @media (min-width: 768px) {
    block-size: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    color: var(--neutral-400);
    min-inline-size: 360px;
  }
}

.isEdgeCase {
  @media (min-width: 1440px) {
    min-inline-size: 18.75rem;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  inline-size: 3.5rem;
  block-size: 3.5rem;
  border-radius: 1rem;
  background: linear-gradient(134.17deg, #e6ebeb 4.98%, #e6e9ef 4.99%, #e6ebeb 94.88%);
  box-shadow: 5.06px 5.45px 4.67px -2.33px #a6b4c891, -7.78px -7.78px 23.73px 0px #ffffff87,
    7.39px 9.72px 35.78px -12.45px #a6b4c873;
  position: relative;
  transition: all var(--transition-normal);
  will-change: box-shadow;

  &::after {
    content: '';
    display: block;
    inline-size: calc(100% + 4px);
    block-size: calc(100% + 4px);
    border-radius: inherit;
    background-image: linear-gradient(135.83deg, #ffffff 22.24%, #e6ebeb 85.59%);
    position: absolute;
    inset: -2px 0px 0 -2px;
    z-index: -1;
  }

  @media (min-width: 768px) {
    inline-size: 8.375rem;
    block-size: 8.375rem;
    border-radius: 50%;
    color: var(--neutral-400);
    box-shadow: 19.52px 19.52px 17.9px -8.13px #a6b4c88c, -32.54px -32.54px 89.48px 0px #ffffff8c,
      32.54px 32.54px 138.28px -48.81px #a6b4c873;
  }
}
