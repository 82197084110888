.root {
  display: inline-flex;
  justify-content: center;
  column-gap: 0.75rem;
  border: none;
  border-radius: 3rem;
  font: var(--font-m);
  text-align: center;
  text-decoration: none;
  color: var(--neutral-white);
  cursor: pointer;
  background-color: var(--brand-400);
  transition: background-color var(--transition-normal);

  &:not(:disabled):hover,
  &:not(:disabled):focus-visible {
    background-color: var(--brand-400-active);
  }

  &:not(:disabled):focus-visible {
    outline: 2px solid var(--brand-400-active);
    outline-offset: 2px;
  }

  &:disabled {
    background-color: var(--neutral-100);
    color: var(--neutral-200);
    cursor: not-allowed;
  }
}

.isFullWidth {
  inline-size: min(100%, 20.5rem);
}

.isSecondary {
  justify-content: space-between;
  border-radius: 1.25rem;
  color: var(--neutral-400);
  background-color: var(--neutral-white);

  &:not(:disabled):hover,
  &:not(:disabled):focus-visible {
    background-color: var(--brand-200);
  }
}

.isNegative {
  color: var(--neutral-white);
  background-color: var(--neutral-400);

  &:not(:disabled):hover,
  &:not(:disabled):focus-visible {
    background-color: var(--neutral-300);
  }
}

.isGosht {
  color: var(--neutral-400);
  /* NOTE: Safari 16 doesn't round outline */
  box-shadow: inset 0 0 0px 1px var(--neutral-400);
  background-color: transparent;

  &:not(:disabled):hover,
  &:not(:disabled):focus-visible {
    background-color: var(--brand-200);
  }
}

.medium {
  padding: 0.75rem var(--space-s);
}

.large {
  padding: var(--space-l);
}
