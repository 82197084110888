details > summary {
  list-style: none;
}
details > summary::-webkit-details-marker {
  display: none;
}

.root {
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  background-color: var(--neutral-white);
}
.root[open] {
  .collapsableTitle svg {
    transform: rotate(-180deg);
  }
}

.summary {
  padding: var(--space-l) var(--space-s);
  cursor: pointer;

  @media (min-width: 640px) {
    padding: var(--space-l);
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--space-s);

  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-400);

  svg {
    transition: transform var(--transition-normal) ease-in-out;
  }
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-l);
  padding: 0 var(--space-s) var(--space-s);

  font: var(--font-m);
  font-weight: 400;
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-300);

  @media (min-width: 640px) {
    padding: 0 var(--space-l) var(--space-l);
  }
}
