.root {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 4.5rem;

  @media (min-width: 1024px) {
    padding-block: 11rem;
  }
}
.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem 4rem;
  inline-size: 100%;
  margin-inline: auto;
  font-size: 1.25rem;
  font-weight: 400;
  text-decoration: none;
  color: var(--neutral-200);
  @media (min-width: 768px) {
    flex-direction: row;
  }
  @media (min-width: 1024px) {
    font-size: 2.5rem;

    &:hover,
    &:focus-visible {
      & svg {
        transform: scale(1.05);
      }
    }
  }
}
.title {
  text-decoration: none;
  color: var(--neutral-200);

  &:hover,
  &:focus-visible {
    outline-color: var(--brand-300);

    & .icon {
      box-shadow: 8px 8px 11.17px -5.58px #a6b4c891, 0px 0px 56.77px 0px #ffffff87,
        -20px 20px 85.63px -29.78px #a6b4c873;
    }

    & svg {
      color: var(--brand-400);
      transform: scale(0.8);
    }
  }

  @media (min-width: 1024px) {

    &:hover,
    &:focus-visible {
      & svg {
        transform: scale(1.05);
      }
    }
  }
}

.text {
  text-align: center;
}

@media (min-width: 768px) {
  .text {
  inline-size: min(33%, 18rem);
  text-align: left;
}
}

.alignRight {
  @media (min-width: 768px) {
    text-align: right;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1 / 1;
  inline-size: 15.625rem;
  block-size: auto;
  border-radius: 50%;
  background: linear-gradient(134.17deg, #e6ebeb 4.98%, #e6e9ef 4.99%, #e6ebeb 94.88%);
  box-shadow: 5.06px 5.45px 4.67px -2.33px #a6b4c891, -7.78px -7.78px 23.73px 0px #ffffff87,
    7.39px 9.72px 35.78px -12.45px #a6b4c873;
  position: relative;
  transition: all var(--transition-normal);
  will-change: box-shadow;

  &::after {
    content: '';
    display: block;
    inline-size: calc(100% + 4px);
    block-size: calc(100% + 4px);
    border-radius: inherit;
    background-image: linear-gradient(135.83deg, #ffffff 22.24%, #e6ebeb 85.59%);
    position: absolute;
    inset: -2px 0px 0 -2px;
    z-index: -1;
  }

  @media (min-width: 1024px) {
    inline-size: 18rem;
  }
  @media (min-width: 1200px) {
    inline-size: 22.5rem;
  }
}

.icon > svg {
  color: #c1cbd7cc;
  transform: scale(0.7);
  transition: all var(--transition-normal);

  @media (min-width: 1024px) {
    transform: none;
  }
}
