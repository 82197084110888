.card {
  min-block-size: 342px;

  display: flex;
  flex-direction: column;
  row-gap: var(--space-xl);
  min-inline-size: var(--card-inline-size);
  max-inline-size: var(--card-inline-size);
  padding: var(--space-l);
  border-radius: 1.5rem;
  background-color: var(--neutral-white);
  position: relative;

  &:first-of-type {
    margin-inline-start: auto;
  }
  &:last-of-type {
    margin-inline-end: auto;
  }
}

.header {
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
  position: relative;
}

.header > svg {
  min-inline-size: 3rem;
  min-block-size: 3rem;
}

.headerInfo {
  display: flex;
  flex-direction: column;
  row-gap: 0.125rem;
}

.title {
  font: var(--font-m-bold);
  letter-spacing: var(--font-m-bold-letter-spacing);
  color: var(--neutral-400);

  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 13rem;
  overflow: hidden;
}

.subtitle {
  font: var(--font-s);
  letter-spacing: var(--font-s-letter-spacing);
  color: var(--neutral-200);

  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 13rem;
  overflow: hidden;
}

.close {
  display: flex;
  padding: var(--space-xs);
  border: none;
  color: var(--neutral-200);
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  background-color: transparent;
  transform: translate(0.5rem, -0.5rem);
  transition: color var(--transition-normal);
  cursor: pointer;

  &:hover,
  &:focus-visible {
    color: var(--neutral-400);
  }
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: var(--space-xl);
}

.promotedText {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-inline-start: 1.125rem;

  &::before {
    content: '';
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    inline-size: 6px;
    block-size: 100%;
    border-radius: 100px;
    background-color: var(--promoted-text-color);
  }
}

.promotedText > strong {
  font: var(--font-l-bold);
  letter-spacing: var(--font-l-bold-letter-spacing);
  color: var(--neutral-400);
}

.promotedText > span {
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-300);
}

.promotedTextBest {
  --promoted-text-color: var(--brand-400);
}

.promotedTextDefault {
  --promoted-text-color: var(--status-success);
}

.description {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-m);
  padding: 0.75rem;
  border-radius: 0.5rem;
  white-space: normal;
  background-color: var(--brand-100);

  :is(p, li) {
    font: var(--font-s);
    letter-spacing: var(--font-s-letter-spacing);
    color: var(--neutral-300);
  }

  ul {
    display: flex;
    flex-direction: column;
    padding-inline-start: 0;
    margin-block: 0;
    font: var(--font-s);
    letter-spacing: var(--font-s-letter-spacing);
    color: var(--neutral-300);
  }

  li {
    display: grid;
    grid-template-columns: 1rem 1fr 3.5rem;
    column-gap: var(--space-s);
  }

  svg {
    transform: translateY(0.125rem);
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  column-gap: var(--space-s);
}

.footerText {
  display: flex;
  flex-direction: column;
  row-gap: 0.125rem;
}

.footerText > span {
  font: var(--font-s);
  letter-spacing: var(--font-s-letter-spacing);
  color: var(--neutral-200);
}

.footerText > strong {
  font: var(--font-m-bold);
  letter-spacing: var(--font-m-bold-letter-spacing);
  color: var(--neutral-400);
}

.footerTextRight {
  text-align: end;
}
