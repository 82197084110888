.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1rem;
}

.logo {
  inline-size: 121px;
}

.menuItems {
  display: flex;
  column-gap: 1rem;
}

@media (max-width: 768px) {
  .menuItems a:nth-child(2) {
    display: none;
  }
}

.button {
  padding-inline: 1.81rem;
}
