.root {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-xs);
}

.header {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
  gap: var(--space-xs);
  padding-inline: var(--space-s);
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-200);

  @media (min-width: 768px) {
    grid-template-columns: 2fr 2fr 1fr 5rem;
    padding-inline: var(--space-l);
    font: var(--font-s);
    letter-spacing: var(--font-s-letter-spacing);
    color: var(--neutral-300);
  }
}

.header > span {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 768px) {
    &:last-of-type {
      display: none;
    }
  }
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-xs);
  padding-inline-start: 0;
  margin-block: 0;
  list-style: none;
}

.listItemWrapper {
  position: relative;

  &:hover,
  &:focus-visible {
    .downloadButton {
      opacity: 1;
    }
  }
}

.listItem {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
  align-items: center;
  gap: var(--space-xs);
  padding: var(--space-s);
  border-radius: 1rem;
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-400);
  text-decoration: none;
  background: var(--neutral-white);
  outline: 1px solid transparent;
  transition: outline-color var(--transition-normal) ease-in-out;

  @media (min-width: 768px) {
    grid-template-columns: 2fr 2fr 1fr 5rem;
    padding-inline: var(--space-l);
    color: var(--neutral-300);
  }

  &:hover,
  &:focus-visible {
    outline-color: var(--neutral-200);
  }
}

.listItem > span {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.isEmpty {
  opacity: 0.5;
  pointer-events: none;
}

.downloadButton {
  @media (max-width: 768px) {
    display: none;
  }

  padding: var(--space-s);
  border: 0;
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  text-decoration: none;
  color: var(--brand-400);
  background-color: transparent;
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  opacity: 0;
  cursor: pointer;
  transition: color var(--transition-normal) ease-in-out,
    opacity var(--transition-normal) ease-in-out;

  &:hover,
  &:focus-visible {
    color: var(--brand-400-active);
  }
}
