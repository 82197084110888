.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
  inline-size: min(100%, 35rem);
  padding-inline: var(--space-s);
  margin-inline: auto;

  @media (min-width: 768px) {
    row-gap: 2.5rem;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5rem;
  inline-size: min(100%, 26.25rem);
  text-align: center;
}

.heading {
  font: var(--font-xl);
  letter-spacing: var(--font-xl-letter-spacing);
  color: var(--neutral-400);
}

.subheading {
  font: var(--font-l);
  color: var(--neutral-200);
  letter-spacing: var(--font-l-letter-spacing);
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
  inline-size: min(100%, 26.5rem);
}

.embeddedSigningContainer {
  /* NOTE: Exception */
  margin-block-end: auto;

  aspect-ratio: 1 / 1;
  inline-size: min(100%, 700px);
  block-size: auto;
  margin-block-start: auto;
  border-radius: 1.25rem;
  background-color: var(--brand-200);

  @media (min-width: 768px) {
    inline-size: 700px;
  }
}
