.root {
  text-decoration: none;
  color: var(--brand-400);
  transition: color var(--transition-normal);

  &:not(:disabled):hover,
  &:not(:disabled):focus-visible {
    color: var(--brand-400-active);
  }

  &:not(:disabled):focus-visible {
    outline: 2px solid var(--brand-400-active);
    outline-offset: 2px;
  }
}
