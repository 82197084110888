.root {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  gap: 1.5rem;
}

.wrapper {
  display: flex;
  overflow: hidden;
}
