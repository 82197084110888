.root {
  background-color: var(--brand-200);
  border-radius: 1.25rem;
  padding: 1.5rem;
}
@media (min-width: 990px) {
  .root {
    padding: 2.5rem;
  }
}
.box {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
@media (min-width: 990px) {
  .box {
    flex-direction: row;
    justify-content: space-between;
  }
}
.boxTexts {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
@media (min-width: 990px) {
  .boxTexts {
    width: 75%;
    justify-content: space-between;
  }
}
@media (min-width: 1200px) {
  .boxTexts {
    width: 70%;
  }
}

.boxIcons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  margin: 0;
  padding: 0;
}
@media (min-width: 1024px) {
  .boxIcons {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: flex-start;
  }
}

.boxIcons li {
  width: 100%;
  background-color: var(--neutral-white);
  border-radius: 1rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
}
@media (min-width: 990px) {
  .boxIcons li {
    width: 50%;
  }
}
@media (min-width: 1024px) {
  .boxIcons li {
    width: 33%;
  }
}

.boxIcons li span {
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.04rem;
  color: var(--neutral-200);
}
@media (min-width: 768px) {
  .boxIcons li span {
    font-size: 0.75rem;
  }
}
@media (min-width: 1200px) {
  .boxIcons li span {
    font-size: 0.93rem;
  }
}
@media (min-width: 1400px) {
  .boxIcons li span {
    font-size: 1rem;
  }
}

@media (min-width: 990px) {
  .image {
    width: 25%;
    justify-content: space-between;
  }
}
@media (min-width: 1200px) {
  .image {
    width: 30%;
  }
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
}
@media (min-width: 400px) {
  .image img {
    max-height: 19rem;
    object-position: 0 -7rem;
  }
}
@media (min-width: 600px) {
  .image img {
    max-height: 24rem;
    object-position: 0 -18rem;
  }
}
@media (min-width: 800px) {
  .image img {
    object-position: 0 -31rem;
  }
}
@media (min-width: 990px) {
  .image img {
    max-height: inherit;
    object-position: inherit;
  }
}
.title {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: -0.02rem;
  color: var(--neutral-400);
  padding: 1rem 0.5rem 0.5rem;
  text-align: center;
}
@media (min-width: 990px) {
  .title {
    font-size: 2.5rem;
    letter-spacing: -0.07rem;
    text-align: left;
  }
}
.description {
  margin: 0 auto;
  font: var(--font-m);
  font-weight: 500;
  letter-spacing: var(--font-m-letter-spacing);
  text-align: center;
  color: var(--neutral-200);
  padding: 0.5rem 0.5rem 1rem;
}
@media (min-width: 990px) {
  .description {
    font: var(--font-lAlt);
    letter-spacing: var(--font-lAlt-letter-spacing);
    text-align: left;
  }
}
