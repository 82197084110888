.row {
  display: flex;
  column-gap: var(--space-xs);
}

.formField {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  inline-size: 100%;
  position: relative;
}

.label {
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-300);
  position: absolute;
  top: 0.875rem;
  left: 0.75rem;
  z-index: 1;
  transition: all var(--transition-normal);
  pointer-events: none;
}

.input {
  min-block-size: 3.125rem;
  box-sizing: border-box;
  padding: 1.375rem 0.75rem 0.375rem;
  inline-size: 100%;
  border-radius: 1rem;
  border: 1px solid transparent;
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  background-color: var(--neutral-white);
  transition: border-color var(--transition-normal);
  position: relative;

  &:focus-visible,
  &:hover {
    border-color: var(--neutral-200);
  }
}

.loader {
  inline-size: 3rem;
  block-size: 3rem;
  border-radius: 50%;
  background: radial-gradient(farthest-side, var(--brand-400) 94%, #0000) top/4px 4px no-repeat,
    conic-gradient(#0000 30%, var(--brand-400));
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  animation: s3 1s infinite linear;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

@keyframes s3 {
  100% {
    transform: rotate(1turn);
  }
}