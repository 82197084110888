.root {
  display: flex;
  flex-direction: column;
  min-block-size: 100dvh;
  row-gap: var(--space-l);
  padding: var(--space-l) var(--space-s) var(--space-2xl);

  @media (min-width: 768px) {
    row-gap: var(--space-3xl);
    padding-block: var(--space-xl) var(--space-4xl);
  }
}

.header {
  display: flex;
  justify-content: center;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-block-size: 0;
}
