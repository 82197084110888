.root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  row-gap: var(--space-s);
  min-block-size: 36rem;
  padding-inline: 1rem;

  @media (min-width: 1024px) {
    container-name: dashboard;
    container-type: size;
    padding-inline: 4vw;
  }
}

.root:has(.notificationsWrapper) {
  min-block-size: 39rem;
}

.root:has(.notificationsWrapper):has(.hasList) {
  min-block-size: 41rem;
}

.notificationsWrapper {
  inline-size: min(100%, var(--max-width));
  margin-inline: auto;
}

.inner {
  flex-grow: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  row-gap: var(--space-s);
  inline-size: min(100%, var(--max-width));
  block-size: 100%;
  margin-inline: auto;

  @media (min-width: 1024px) {
    grid-template-columns: 1fr;
    grid-template-rows: var(--contract-row-height) 1fr;
  }
}

.hasList {
  --contract-row-height: 276px;

  @container dashboard (min-block-size: 720px) {
    --contract-row-height: 311px;
  }

  @container dashboard (min-block-size: 780px) {
    --contract-row-height: 350px;
  }
}

.hasCards {
  --contract-row-height: 250px;

  @container dashboard (min-block-size: 720px) {
    --contract-row-height: 280px;
  }

  @container dashboard (min-block-size: 780px) {
    --contract-row-height: 330px;
  }
}

.row {
}

.rowContracts {
  display: grid;
  gap: var(--space-s);

  inline-size: calc(100% + 2rem);
  block-size: var(--contract-row-height, 100%);
  padding-inline: 1rem;
  margin-inline: auto;
  transform: translateX(-1rem);

  grid-template-columns: repeat(var(--contract-row-columns, 1), minmax(18.75rem, 1fr));
  grid-auto-flow: column;

  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scrollbar-gutter: stable both-edges;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 640px) {
    grid-template-columns: repeat(var(--contract-row-columns), minmax(315px, 1fr));
  }
  @media (min-width: 1024px) {
    inline-size: calc(100% + 8vw);
    padding-inline: 4vw;
    transform: translateX(-4vw);
  }
  @media (min-width: 1440px) {
    inline-size: 100%;
    padding-inline: 0;
    transform: none;
  }
}

.isEdgeCase {
  @media (min-width: 1024px) {
    grid-template-columns: repeat(var(--contract-row-columns), minmax(315px, 1fr)) 360px;
  }
  @media (min-width: 1440px) {
    grid-template-columns: repeat(var(--contract-row-columns), minmax(315px, 1fr)) 18.75rem;
  }
}

.listLayout {
  grid-template-columns: 1fr;
}
