.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  padding-block-end: var(--space-2xl);

  @media (min-width: 768px) {
    row-gap: 3rem;
    padding-block-end: var(--space-4xl);
  }
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--space-l) var(--space-m);
  position: relative;

  @media (min-width: 768px) {
    &::after {
      content: '';
      display: block;
      inline-size: 92vw;
      block-size: 1px;
      position: absolute;
      inset-block-end: 0;
      inset-inline-end: 4vw;
      background-color: var(--neutral-200);
    }
  }
}

.headerText {
  font: var(--font-s);
  color: var(--neutral-300);
}

.headerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  inline-size: 1.5rem;
  block-size: 1.5rem;
  padding: 0;
  border: 0;
  font: var(--font-m);
  color: var(--neutral-400);
  position: absolute;
  inset-block-start: 50%;
  inset-inline-end: var(--space-m);
  transform: translateY(-50%);
  background-color: transparent;
}

.headerList {
  display: flex;
  align-items: center;
  column-gap: var(--space-2xl);
  padding-inline-start: 0;
  margin-block: 0;
  list-style-type: none;
}

.headerItem {
  --stepper-color: var(--neutral-300);
  --stepper-number-color: var(--neutral-300);
  --stepper-number-background-color: transparent;
  --stepper-outline: 1px solid var(--neutral-300);

  display: flex;
  column-gap: var(--space-xs);
  font: var(--font-s);
  letter-spacing: var(--font-s-letter-spacing);
  color: var(--stepper-color);
}

.headerItemActive {
  --stepper-color: var(--neutral-400);
  --stepper-number-color: var(--brand-100);
  --stepper-number-background-color: var(--brand-400);
  --stepper-outline: 0;
}

.headerItemValue {
  display: flex;
  justify-content: center;
  inline-size: 1.5rem;
  block-size: 1.5rem;
  font: var(--font-s);
  color: var(--stepper-number-color);
  position: relative;

  &::after {
    content: '';
    display: block;
    inline-size: 1.5rem;
    block-size: 1.5rem;
    border-radius: 50%;
    outline: var(--stepper-outline);
    outline-offset: -1px;
    background-color: var(--stepper-number-background-color);
    position: absolute;
    inset-block-start: -1px;
    z-index: -1;
  }
}

.headerItemCompleted {
  --stepper-color: var(--neutral-400);
  --stepper-number-color: var(--neutral-400);
  --stepper-number-background-color: var(--neutral-400);
  --stepper-outline: 0;

  &::after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    inline-size: 1.5rem;
    block-size: 1.5rem;
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.99992L3.66667 6.66659L9 1.33325' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 5px 6px;
    background-size: 14px;
  }
}

.content {
  flex: 1;
  display: flex;
}
