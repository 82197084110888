.root {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-l);
  block-size: var(--contract-row-height);
  padding: 1rem;
  border-radius: 1.25rem;
  background-color: var(--neutral-white);
  transition: all var(--transition-normal);
  outline: 1px solid var(--brand-300);
  outline-offset: -1px;
  overflow: hidden;
}

.title {
  font: var(--font-l);
  letter-spacing: var(--font-l-letter-spacing);
  color: var(--neutral-400);
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: 0;
  padding-inline-start: 0;
  margin-block: 0;
  list-style: none;

  @media (min-width: 768px) {
    row-gap: var(--space-xxs);
  }
  @media (min-width: 1024px) {
    overflow: auto;
  }
}

.item {
  display: grid;
  grid-template-columns: repeat(2, 1fr) auto;
  gap: var(--space-xs);
  padding: 0.5rem;
  font: var(--font-s);
  letter-spacing: var(--font-s-letter-spacing);
  color: var(--neutral-300);
  position: relative;
  transition: all var(--transition-normal);

  &:hover,
  &:focus-visible {
    color: var(--neutral-300);
    background-color: var(--brand-100);
  }

  @media (min-width: 768px) {
    padding: 0.25rem;
  }
  @media (min-width: 1024px) {
    grid-template-columns: minmax(0, 4fr) minmax(0, 1fr) minmax(0, 1fr) 1.25rem;
    border-radius: 1rem;
  }
}

.item:not(:last-of-type) {
  border-bottom: 1px solid var(--brand-200);

  @media (min-width: 1024px) {
    border-bottom: none;
  }
}

.item > :is(.address, .lastInvoice, .supplier) {
  position: relative;
  z-index: 1;
  pointer-events: none;
}

.itemHeader {
  padding-block: 0 0.125rem;
  border-radius: 0;
  font: var(--font-xs);
  letter-spacing: var(--font-xs-letter-spacing);
  color: var(--neutral-200);
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: var(--neutral-white);
}

.address {
  grid-column: 1 / -1;
  display: flex;
  align-items: center;
  column-gap: var(--space-xs);

  @media (min-width: 1024px) {
    grid-column: 1 / 2;
  }
}

:is(.lastInvoice, .supplier, .action) {
  display: flex;
  align-items: end;

  @media (min-width: 1024px) {
    align-items: center;
  }

  span {
    display: inline-block;
    max-width: 8.25rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.action {
}

.action > a {
  display: flex;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
  }
}

.action > svg {
  display: flex;
}
