.heading {
  font: var(--font-l);
  letter-spacing: var(--font-l-letter-spacing);
  color: var(--neutral-400);
}

.subheading {
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-200);
}

.action {
  flex: 1;
  display: flex;
  padding-block-start: var(--space-m);
}

.action div:not([class]) {
  flex: 1;
  display: flex;
}

.action > [class*='root'] {
  flex: 1;
}

.errorWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.actionError {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-block-start: var(--space-m);
}
