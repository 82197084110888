.root {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-s);
  font: var(--font-s);
  letter-spacing: var(--font-s-letter-spacing);
  color: var(--neutral-400);

  @media (min-width: 1024px) {
    padding-block-end: var(--space-l);
  }

  span {
    font-size: 2rem;
    font-weight: 700;
    white-space: nowrap;
  }
}

.textDimmed {
  font: var(--font-s);
  letter-spacing: var(--font-s-letter-spacing);
  color: var(--neutral-200);

  span {
    font-size: 1rem;
    font-weight: 600;
  }
}
