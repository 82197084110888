.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3.5rem;
  inline-size: min(100%, 35rem);
  margin-inline: auto;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5rem;
  text-align: center;
}

.heading {
  font: var(--font-xl);
  letter-spacing: var(--font-xl-letter-spacing);
  color: var(--neutral-400);
}

.subheading {
  font: var(--font-l);
  color: var(--neutral-200);
}

.content {
  flex: 1;
  display: flex;
  inline-size: min(100%, 20.5rem);
}

.requirements {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-xs);
  margin-block: var(--space-l) 0;
  padding-inline-start: 0;
  font: var(--font-m);
  color: var(--neutral-200);
}

.requirements li {
  list-style-type: none;
  padding-inline-start: var(--space-l);
  position: relative;
}

.requirements li.isValid::before {
  content: '';
  min-inline-size: 1rem;
  min-block-size: 1rem;
  background: url("data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.8634 3.40735C15.0508 3.59487 15.1562 3.84918 15.1562 4.11435C15.1562 4.37951 15.0508 4.63382 14.8634 4.82135L7.36803 12.3167C7.26898 12.4158 7.15138 12.4943 7.02195 12.548C6.89252 12.6016 6.7538 12.6292 6.6137 12.6292C6.4736 12.6292 6.33488 12.6016 6.20545 12.548C6.07602 12.4943 5.95842 12.4158 5.85937 12.3167L2.13537 8.59335C2.03986 8.5011 1.96367 8.39076 1.91126 8.26875C1.85886 8.14675 1.83127 8.01553 1.83012 7.88275C1.82896 7.74997 1.85426 7.61829 1.90454 7.49539C1.95483 7.3725 2.02908 7.26084 2.12297 7.16695C2.21686 7.07306 2.32852 6.99881 2.45141 6.94853C2.57431 6.89824 2.70599 6.87294 2.83877 6.8741C2.97155 6.87525 3.10277 6.90284 3.22477 6.95525C3.34677 7.00765 3.45712 7.08384 3.54937 7.17935L6.61337 10.2433L13.4487 3.40735C13.5416 3.31442 13.6518 3.2407 13.7732 3.19041C13.8946 3.14011 14.0247 3.11423 14.156 3.11423C14.2874 3.11423 14.4175 3.14011 14.5389 3.19041C14.6602 3.2407 14.7705 3.31442 14.8634 3.40735Z' fill='%2316B25E'/%3E%3C/svg%3E");
  position: absolute;
  inset-inline-start: 0;
}
