.root {
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
  inline-size: min(100%, 55rem);
  min-block-size: 100%;
  margin-inline: auto;

  @media (min-width: 768px) {
    gap: var(--space-2xl);
  }
}

.list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 0 var(--space-l) 0;
  margin: 0;
  gap: var(--space-s);
  list-style: none;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    inset-block-end: 0;
    inset-inline: 0;
    block-size: 1px;
    inline-size: 100%;
    background: var(--neutral-100);
  }

  @media (min-width: 640px) {
    flex-direction: row;
  }
}

.list > li {
  flex: 1;
  inline-size: 100%;
}

.link {
  display: flex;
  align-items: center;
  column-gap: var(--space-l);
  padding: var(--space-s) var(--space-l) var(--space-s) var(--space-s);
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-400);
  text-decoration: none;
  border: 1px solid var(--brand-300);
  border-radius: 1rem;
  white-space: nowrap;
  background-color: var(--brand-200);
  transition: background-color var(--transition-normal) ease-in-out;

  &[disabled] {
    pointer-events: none;
    cursor: default;
  }

  &:hover,
  &:focus-visible {
    background-color: var(--brand-300);
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
}

.section {
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
}

.sectionContainer {
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
}

.title {
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-300);
}
