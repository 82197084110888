.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 6rem;

  padding-block-start: 3.5rem;

  @media (min-width: 768px) {
    padding-block-start: 4rem;
    row-gap: 5rem;
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  inline-size: 13.5rem;
  block-size: 13.5rem;
  border-radius: 50%;
  background: linear-gradient(134.17deg, #e6ebeb 4.98%, #e6e9ef 4.99%, #e6ebeb 94.88%);
  box-shadow: 19.52px 19.52px 17.9px -8.13px #a6b4c88c, -32.54px -32.54px 89.48px 0px #ffffff8c,
    32.54px 32.54px 138.28px -48.81px #a6b4c873;
  position: relative;

  &:has(.spinner) {
    &::before {
      content: '';
      display: block;
      inline-size: 1.5rem;
      block-size: 1.5rem;
      border-radius: inherit;
      background: inherit;
      position: absolute;
      inset-block-start: 50%;
      inset-inline-start: 50%;
      z-index: 1;
      transform: translate(-50%, -50%);
    }
  }

  &::after {
    content: '';
    display: block;
    inline-size: calc(100% + 4px);
    block-size: calc(100% + 4px);
    border-radius: inherit;
    background-image: linear-gradient(135.83deg, #ffffff 22.24%, #e6ebeb 85.59%);
    position: absolute;
    inset: -2px 0px 0 -2px;
    z-index: -1;
  }
}

.spinner {
  width: 3rem;
  aspect-ratio: 1;
  display: grid;
  -webkit-mask: conic-gradient(from 22deg, #0003, #000);
  mask: conic-gradient(from 22deg, #0003, #000);
  animation: load 1s steps(8) infinite;

  &,
  &::before {
    --_g: linear-gradient(var(--brand-400) 12% 0) 50%;
    background: var(--_g) / 34% 8% space no-repeat, var(--_g) / 8% 34% no-repeat space;
  }

  &::before {
    content: '';
    transform: rotate(45deg);
  }
}

.text {
  font: var(--font-s);
  text-align: center;
  color: var(--neutral-200);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes load {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}
