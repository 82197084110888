.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
  inline-size: min(100%, 35rem);
  margin-inline: auto;

  @media (min-width: 640px) {
    row-gap: 3rem;
  }
}

.formActions {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: var(--space-l);
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5rem;
  padding-inline: var(--space-s);
  text-align: center;
}

.heading {
  font: var(--font-xl);
  letter-spacing: var(--font-xl-letter-spacing);
  color: var(--neutral-400);
}

.subheading {
  font: var(--font-l);
  letter-spacing: var(--font-l-letter-spacing);
  color: var(--neutral-200);
}

.content {
  --card-inline-size: 21.5rem;

  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  inline-size: 100%;
}

.cardsContainer {
  flex: 1;
  inline-size: calc(100vw - var(--space-s));
  margin-inline-start: var(--space-s);
  margin-inline-end: var(--space-s);
}

.cardsWrapper {
  display: flex;
  flex-wrap: nowrap;
  column-gap: var(--space-xs);
  inline-size: 100%;
  padding-inline-end: var(--space-s);
  white-space: nowrap;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scrollbar-gutter: stable both-edges;
}

.action {
  display: flex;
  justify-content: center;
  padding-inline: var(--space-s);
}
