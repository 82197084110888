.checkbox {
  --checkbox-size: 1rem;
  --checkbox-border-inline-size: 1px;
  --checkbox-color: var(--neutral-300);
  --checkbox-fill-color: var(--brand-400);

  display: flex;
  font: var(--font-xs);
  letter-spacing: var(--font-xs-letter-spacing);
  color: var(--checkbox-color);
  position: relative;
}

.checkbox:hover:not([disabled]),
.checkbox:has(.input:checked) {
  --checkbox-color: var(--neutral-400);
}

.input {
  border: 0px;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0px;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
}

.input:checked + label::before {
  color: var(--checkbox-fill-color);
  background-color: var(--checkbox-fill-color);
}

.input:checked + label::after {
  scale: 1;
}

.label {
  display: inline-flex;
  column-gap: 0.25rem;
  align-items: center;
  position: relative;
  user-select: none;
  cursor: pointer;
  transition: all var(--transition-normal);
}

.label::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  min-inline-size: var(--checkbox-size);
  min-block-size: var(--checkbox-size);
  margin-inline-end: 0.25rem;
  color: inherit;
  border: var(--checkbox-border-inline-size) solid currentColor;
  border-radius: 0.125rem;
  transition: inherit;
}

.label::after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  min-inline-size: 13px;
  min-block-size: 8px;
  position: absolute;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.99992L3.66667 6.66659L9 1.33325' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 3px 0;
  background-size: contain;
  transition: inherit;
  scale: 0;
}
