@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(1rem);
  }
}

.root {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-s);

  @media (min-width: 768px) {
    row-gap: var(--space-l);
  }
}

dialog.isClosing[class] {
  animation: fadeOut 300ms ease-in-out forwards;
}

.detail {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--space-s);
  padding: var(--space-s);
  border-radius: 1.25rem;
  background-color: var(--neutral-white);

  @media (min-width: 768px) {
    grid-template-columns: 30rem 1fr;
    align-items: center;
    padding: var(--space-l);
  }
  @media (min-width: 1200px) {
    column-gap: var(--space-3xl);
  }
}

.map {
  aspect-ratio: 2 / 1;
  inline-size: 100%;
  block-size: 100%;
  border-radius: 1.25rem;
  background-color: var(--brand-300);
  overflow: hidden;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    display: block;
    block-size: 100%;
    inline-size: 100%;
    position: absolute;
    z-index: 401;
    inset: 0;
    background-image: linear-gradient(rgba(66 77 92 / 0.45), rgba(66 77 92 / 0.45));
    filter: opacity(0.75);
  }
}

.map [class*='leaflet-layer'],
.map [class*='leaflet-control-zoom-in'],
.map [class*='leaflet-control-zoom-out'],
.map [class*='leaflet-control-attribution'] {
  filter: hue-rotate(50deg) saturate(2) brightness(1);
}

.map [class*='leaflet-container'] {
  background: rgb(66 77 92);
}

.listWrapper {
  container-type: inline-size;
  container: listWrapper / inline-size;
}

.list {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--space-l) var(--space-s);
  margin-block: 0;
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);

  @container (min-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @container (min-width: 640px) {
    gap: var(--space-l) var(--space-3xl);
  }
}

.item {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-xxs);
}

.term {
  color: var(--neutral-400);
}

.definition {
  margin-inline-start: 0;
  color: var(--neutral-200);
}

.actions {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--space-s);
  padding-inline-start: 0;
  margin-block-start: 0;
  list-style: none;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1024px) {
    gap: var(--space-l);
  }
}

.button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  inline-size: 100%;
  padding: var(--space-l) var(--space-m);
  border: 0;
  border-radius: 1.25rem;
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-300);
  background-color: var(--neutral-white);
  outline: 1px solid transparent;
  cursor: pointer;
  transition: outline-color var(--transition-normal) ease-in-out;

  &:hover,
  &:focus-visible {
    outline-color: var(--brand-300);
  }

  @media (min-width: 1024px) {
    padding: var(--space-l);
  }
}
