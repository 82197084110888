.root {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  inline-size: 100%;
  position: relative;
}

.root.isFocused input {
  /* NOTE: Business decision to remove the outline  */
  outline: none;
  border-color: var(--neutral-200);
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .root.isFocused .label,
    .root:has(input:not([value='']):not(:placeholder-shown)),
    .root:has(textarea:not(:placeholder-shown)) .label {
      font-size: 0.75rem;
      transform: translateY(-0.4375rem);
      color: var(--neutral-200);
    }
  }
}

.root.isFocused .label,
.root:has(input:not(:placeholder-shown), textarea:not(:placeholder-shown)) .label {
  font-size: 0.75rem;
  transform: translateY(-0.4375rem);
  color: var(--neutral-200);
}

.root.hasError input {
  border-color: var(--status-error);
}

.root.isDisabled {
  pointer-events: none;
}

.root.isDisabled .input {
  color: var(--neutral-200);
  background-color: var(--neutral-100);
}

.root.isDisabled .label {
  color: var(--neutral-200);
}

.root textarea {
  flex: 1;
  resize: none;
}

.input {
  box-sizing: border-box;
  padding: 1.375rem 0.75rem 0.375rem;
  inline-size: 100%;
  border-radius: 1rem;
  border: 1px solid transparent;
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-400);
  background-color: var(--neutral-white);
  transition: border-color var(--transition-normal);
  position: relative;

  &:focus-visible,
  &:hover {
    border-color: var(--neutral-200);
  }
}

.input.isDark {
  border: 0;
  background-color: var(--brand-100);
}

.label {
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-300);
  position: absolute;
  top: 0.875rem;
  left: 0.75rem;
  z-index: 1;
  transition: all var(--transition-normal);
  pointer-events: none;
}

.errorMsg {
  padding-inline-start: 1rem;
  font: var(--font-xs);
  color: var(--status-error);
}
