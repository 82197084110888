.root {
  inline-size: 100vw;
  padding: 2rem 1rem;
  margin-inline-start: calc(50% - 50vw);
  border-radius: 1.5rem 1.5rem 0 0;
  color: var(--neutral-250);
  background-color: var(--neutral-400);
}

@media (min-width: 768px) {
  .root {
    padding: 2rem 3rem;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logoWrapper {
  padding: 4.25rem 1rem 7rem;
}

.logo {
  inline-size: 132px;
}

@media (min-width: 990px) {
  .logo {
    inline-size: 280px;
  }
}

.boxLinks {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: -0.02rem;
}

@media (min-width: 990px) {
  .boxLinks {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
  }
}

.boxLinks a {
  color: var(--neutral-250);
  text-decoration: none;
}

.boxLinks a:hover {
  color: var(--neutral-100);
  transition: color var(--transition-normal);
}

.list {
  list-style: none;
  padding: 1.5rem 0;
  margin: 1.5rem 0;
  border-top: 1px solid rgba(217, 217, 217, 0.24);
  border-bottom: 1px solid rgba(217, 217, 217, 0.24);
}

@media (min-width: 990px) {
  .list {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 2.25rem;
    padding: 0;
    margin: 0;
    border: none;
  }
}

.list li {
  padding: 0.5rem 0;
}
