.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: var(--space-xl);
  padding: var(--space-l);
  border-radius: 1.5rem;
  position: absolute;
  inset: 0;
  white-space: normal;
  text-align: center;
  opacity: 0;
  transform: scale(0.9);
  transition: opacity var(--transition-normal) ease-in-out,
    transform var(--transition-normal) ease-in-out;
  background-color: var(--neutral-white);
  pointer-events: none;
}

.isOpen {
  opacity: 1;
  transform: scale(1);
  pointer-events: auto;
}

.title {
  flex-grow: 1;
  display: flex;
  align-items: center;
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  text-align: center;
  color: var(--neutral-400);
}

.actions {
  display: flex;
  align-items: center;
  column-gap: var(--space-s);
  inline-size: 100%;
}

.actions button {
  flex: 1;
}
