@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(calc(-50% - 1rem));
  }
}

@keyframes fadeInDesktop {
  to {
    opacity: 1;
    transform: translate(-50%, calc(-50% - 1rem));
  }
}

.root {
  max-height: 26.5rem;
  max-inline-size: calc(100vw - 2rem);
  inline-size: calc(100vw - 3rem);
  block-size: 26.5rem;
  margin: 1rem;
  padding: 0;
  border: 0;
  border-radius: 1.25rem;
  inset: 50% 0 0 0;
  background-color: var(--brand-100);

  opacity: 0;
  transform: translate(0, -50%);

  @media (min-width: 640px) {
    max-inline-size: 21.5rem;
    min-block-size: 22rem;
    block-size: auto;
    max-block-size: max-content;
    inset: 50% 0 0 50%;
    transform: translate(-50%, -50%);
  }
}

.root::backdrop {
  background-color: var(--overlay-100);
}

.root[open] {
  animation: fadeIn var(--transition-normal) ease-in-out forwards;

  @media (min-width: 640px) {
    animation: fadeInDesktop var(--transition-normal) ease-in-out forwards;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  min-block-size: 100%;

  @media (min-width: 768px) {
    row-gap: 2.5rem;
  }
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: var(--space-2xl);
  justify-content: space-between;
}

.confirm {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: var(--space-xl);
  align-items: center;
  padding: var(--space-l);
  border-radius: 1.5rem;
  text-align: center;
  transition: opacity var(--transition-normal) ease-in-out,
    transform var(--transition-normal) ease-in-out;
  background-color: var(--neutral-white);
}

.title {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding-block: 5rem 3rem;
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-400);
}

.actions {
  display: flex;
  align-items: center;
  column-gap: var(--space-s);
  inline-size: 100%;
}

.actions button {
  flex: 1;
}
