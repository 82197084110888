.radioButtonGroup {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-xs);
  padding: 0;
  margin: 0;
  border: 0;
}

.radioButtonGroupLegend {
  padding-inline: 0;
  margin-block-end: var(--space-s);
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-300);
}

.radioButtonGroupOptions {
  display: flex;
  column-gap: var(--space-s);
}

.root {
  --radio-size: 1rem;
  --radio-border-inline-size: 1px;
  --radio-color: var(--neutral-300);
  --radio-fill-color: var(--brand-400);

  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-300);
}

.input {
  border: 0px;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0px;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
}

.input:checked + label::before {
  color: var(--radio-fill-color);
}

.input:checked + label::after {
  scale: 1;
}

.label {
  display: inline-flex;
  column-gap: 0.5rem;
  position: relative;
  user-select: none;
  cursor: pointer;
  transition: all var(--transition-normal);

  outline: 2px solid transparent;
  outline-offset: 2px;
}

.label::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  min-inline-size: var(--radio-size);
  min-block-size: var(--radio-size);
  max-inline-size: var(--radio-size);
  max-block-size: var(--radio-size);
  color: inherit;
  border: var(--radio-border-inline-size) solid currentColor;
  border-radius: 50%;
  transition: inherit;
  transform: translateY(2px);
}

.label::after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  inline-size: calc(var(--radio-size) - 6px);
  block-size: calc(var(--radio-size) - 6px);
  border-radius: 50%;

  position: absolute;
  inset-inline-start: 3px;
  inset-block-start: 5px;
  background-color: var(--radio-fill-color);
  transition: inherit;
  scale: 0;
}
