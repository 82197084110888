.chart {
  display: grid;
  grid-template-columns: repeat(12, minmax(2rem, 1fr));
  grid-template-rows: 1fr;
  column-gap: var(--space-xs);
  align-items: flex-end;
  block-size: 100%;

  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scrollbar-gutter: stable both-edges;

  margin-inline-start: -0.875rem;
  padding-inline-start: 0.875rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(12, minmax(2rem, 3rem));
  }
  @media (min-width: 1200px) {
    inline-size: max-content;
    column-gap: var(--space-s);
  }
}

.hasValue {
  position: relative;

  &::before {
    /* content: ''; */
    block-size: 1px;
    inline-size: 100%;
    position: absolute;
    inset-block-end: var(--graph-average-block-size);
    inset-inline: 0;
    background: repeating-linear-gradient(
      to right,
      var(--neutral-200),
      var(--neutral-200) 4px,
      transparent 4px,
      transparent 8px
    );
    pointer-events: none;
  }
}

.chartBar {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  row-gap: var(--space-xs);
  block-size: 100%;
}

.chartValue {
  --graph-front-color: var(--neutral-100);

  block-size: var(--graph-block-size, 50%);
  padding: 0;
  border-radius: 1rem;
  border: 0;
  background-color: var(--graph-front-color);
  transition: background-color var(--transition-normal);
}
.chartValue.withContent {
  --graph-front-color: var(--brand-300);

  &:hover,
  &:focus-visible {
    --graph-front-color: var(--neutral-400);
  }
}

.chartValue.isActive {
  --graph-front-color: var(--neutral-400);
}

.chartText {
  font: var(--font-xs);
  letter-spacing: var(--font-xs-letter-spacing);
  color: var(--neutral-200);
  text-align: center;
  text-transform: capitalize;
}
