@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDesktop {
  to {
    opacity: 1;
    transform: translate(-50%, calc(-50% + 1rem));
  }
}

.root {
  max-height: calc(100dvh - 2rem);
  max-inline-size: calc(100vw - 2rem);
  inline-size: calc(100vw - 2rem);
  block-size: calc(100dvh - 2rem);
  padding-inline: 1.5rem;
  margin: 1rem;
  border: 0;
  border-radius: 1.25rem;
  inset: 0;
  background-color: var(--brand-100);

  opacity: 0;
  transform: translateY(1rem);

  @media (min-width: 640px) {
    max-width: 30rem;
    block-size: auto;
    max-height: max-content;
    padding: var(--space-3xl) var(--space-2xl);
    inset: 50% 0 0 50%;
    transform: translate(-50%, calc(-50% + 1rem));
  }

  &::backdrop {
    background-color: var(--overlay-100);
  }
}

.root[open] {
  animation: fadeIn var(--transition-normal) ease-in-out forwards;

  @media (min-width: 640px) {
    animation: fadeInDesktop var(--transition-normal) ease-in-out forwards;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  min-block-size: 100%;

  @media (min-width: 768px) {
    row-gap: 2.5rem;
  }
}

.header {
  display: flex;
  justify-content: flex-end;

  @media (min-width: 640px) {
    display: none;
  }
}

.heading {
  inline-size: min(100%, 20.625rem);
  margin-inline: auto;
  font: var(--font-l);
  letter-spacing: var(--font-l-letter-spacing);
  color: var(--neutral-400);
  text-align: center;

  @media (min-width: 640px) {
    font: var(--font-lAlt);
    letter-spacing: var(--font-lAlt-letter-spacing);
  }
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  inline-size: 2rem;
  block-size: 2rem;
  padding: 0;
  border: 0;
  background-color: transparent;
  opacity: 1;
  cursor: pointer;
  transition: opacity var(--transition-normal);

  &:hover,
  &:focus-visible {
    opacity: 0.7;
  }
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: var(--space-2xl);
  justify-content: space-between;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: var(--space-s);

  @media (min-width: 640px) {
    inline-size: min(100%, 20rem);
    flex-direction: row;
    justify-content: center;
    margin-inline: auto;
  }
}

.actions > button {
  flex: 1;
}
