.root {
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3rem;
  inline-size: min(90vw, 40rem);
  padding: 0 1rem;
  margin-block-start: 2rem;
  margin-inline: auto;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5rem;
  text-align: center;
}

.icon {
  margin-block-end: var(--space-xl);

  @media (min-width: 768px) {
    margin-block-end: var(--space-3xl);
  }
}

.heading {
  font: var(--font-xl);
  letter-spacing: var(--font-xl-letter-spacing);
  color: var(--neutral-400);
}

.subheading {
  font: var(--font-l);
  letter-spacing: var(--font-l-letter-spacing);
  color: var(--neutral-200);
}
