.root {
  inline-size: 100%;
  padding-inline: 1rem;
  margin-block-start: 2.5rem;

  @media (min-width: 768px) {
    margin-block-start: 5rem;
  }
}

.gridButtons {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2x2 grid */
  gap: 1rem;
  justify-content: center;
}

.controlButton {
  padding: 1.5rem;
  border: 1px solid #d6e4e4;
  border-radius: 0.75rem;
  background-color: #fff;
  cursor: pointer;
  font-size: 1.25rem;
  text-align: center;
  transition: background-color 0.2s ease-in-out;
}

.controlButton:hover {
  background-color: #f0f0f0;
}
