.root {
  display: flex;
  column-gap: 0.75rem;
  padding: 1rem;
  border-radius: 1.25rem;
  color: var(--callout-front-color);
  background-color: var(--callout-background-color);
}

.root.info {
  --callout-front-color: var(--status-info);
  --callout-background-color: #167eb226;
}

.root.error {
  --callout-front-color: var(--status-error);
  --callout-background-color: #ff000026;
}

.icon {
  min-inline-size: 1.5rem;
  min-block-size: 1.5rem;
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}

.heading {
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-400);
}

.text {
  font: var(--font-xs);
  letter-spacing: var(--font-xs-letter-spacing);
  color: var(--neutral-300);
}
