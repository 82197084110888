.root {
  container-type: inline-size;
  container-name: card;

  display: flex;
  flex-direction: column;
  row-gap: var(--space-l);
  min-inline-size: 18.75rem;
  block-size: inherit;
  padding: 1rem;
  border: 1px solid var(--brand-300);
  border-radius: 0.75rem;
  background-color: var(--neutral-white);
  transition: all var(--transition-normal);
  position: relative;

  @media (min-width: 640px) {
    min-inline-size: 20rem;
  }
  @media (min-width: 768px) {
    padding: 1.5rem;
    row-gap: var(--space-xl);
  }

  &.noArrow {
    .actionIcon {
      display: none;
    }
  }
}

.link {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  row-gap: var(--space-l);
  text-decoration: none;

  &:not(:disabled):hover,
  &:not(:disabled):focus-visible {
    .actionIcon {
      transform: rotate(-45deg);
    }
  }
}

.icon {
  min-inline-size: 3rem;
  min-block-size: 3rem;
}

.header {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  row-gap: var(--space-xxs);
}

.heading {
  font: var(--font-l);
  letter-spacing: var(--font-l-letter-spacing);
  color: var(--neutral-400);

  @media (min-width: 640px) {
    font: var(--font-lAlt);
    letter-spacing: var(--font-lAlt-letter-spacing);
  }
}

.text {
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-200);
}

.footer {
  display: flex;
  align-items: center;
  gap: var(--space-xs);

  @media (min-width: 640px) {
    gap: var(--space-l);
  }
}

.block,
.action {
  display: flex;
  flex-direction: column;
  font: var(--font-s);
  letter-spacing: var(--font-s-letter-spacing);

  @media (min-width: 768px) {
    font: var(--font-m);
  }
}

.block {
  flex: 1 1 auto;
}

.blockSupplier {
  max-inline-size: 40%;

  @container card (max-width: 340px) {
    max-inline-size: 6rem;
  }
}

.actionIcon {
  min-inline-size: 2rem;
  min-block-size: 2rem;
  transition: transform var(--transition-normal);
}

.value {
  color: var(--neutral-400);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.label {
  color: var(--neutral-200);
  white-space: nowrap;
}
