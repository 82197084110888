.heading {
  font: var(--font-l);
  letter-spacing: var(--font-l-letter-spacing);
  color: var(--neutral-400);

  @media (min-width: 768px) {
    font: var(--font-xl);
    letter-spacing: var(--font-xl-letter-spacing);
  }
}

.subheading {
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-200);

  @media (min-width: 768px) {
    font: var(--font-l);
    letter-spacing: var(--font-l-letter-spacing);
  }
}

.action {
  flex: 1;
  display: flex;
  padding-block-start: var(--space-m);
}

.action div:not([class]) {
  flex: 1;
  display: flex;
}

.action div:not([class]) > [class*='root'] {
  flex: 1;
}

.action button[class] {
  inline-size: 100%;
}
