@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(1rem);
  }
}

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 0;
  border-radius: 0.5rem;
  color: var(--neutral-300);
  background-color: transparent;
  transition: background-color var(--transition-normal);
  cursor: pointer;

  &:hover,
  &:focus-visible {
    fill: var(--color-primary);
    background-color: var(--neutral-white);
  }

  @media (min-width: 768px) {
    padding: 0.25rem 0 0.25rem 0.75rem;
  }
}

dialog.isClosing[class] {
  animation: fadeOut 300ms ease-in-out forwards;
}

.username {
  display: none;

  @media (min-width: 768px) {
    display: block;
    font: var(--font-s);
    letter-spacing: var(--font-s-letter-spacing);
  }
}
