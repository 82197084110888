.root {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem 1rem 1rem;
  border: 1px solid var(--brand-300);
  border-radius: 1.25rem;
  background-color: var(--neutral-white);
  position: relative;

  @media (min-width: 640px) {
    padding: 1.5rem;
  }
}

.heading {
  font: var(--font-l);
  letter-spacing: var(--font-l-letter-spacing);
  color: var(--neutral-400);
}

.text {
  font: var(--font-s);
  letter-spacing: var(--font-s-letter-spacing);
  color: var(--neutral-300);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  inline-size: 1.5rem;
  block-size: 1.5rem;
  padding: 0;
  border: 0;
  color: var(--neutral-200);
  position: absolute;
  inset-block-start: 1rem;
  inset-inline-end: 1rem;
  background-color: var(--neutral-white);
  cursor: pointer;

  @media (min-width: 640px) {
    inset-block-start: 50%;
    inset-inline-end: 1.5rem;
    transform: translateY(-50%);
  }

  &:hover,
  &:focus-visible:not([disabled]) {
    color: var(--neutral-400);
  }
}

.button > svg {
  transition: all var(--transition-fast);
}
