.root {
  /* NOTE: This margin is an exception according to the design */
  margin-block-start: 5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3rem;
  inline-size: min(90vw, 40rem);
  padding: 4rem 1rem 2.5rem;
  margin-inline: auto;
  border-radius: 0.5rem;
  background-color: var(--neutral-white);

  @media (min-width: 768px) {
    padding: 3.5rem 1rem 5.5rem;
    row-gap: 2rem;
  }
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  text-align: center;
}

.heading {
  font: var(--font-xl);
  letter-spacing: var(--font-xl-letter-spacing);
  color: var(--neutral-400);
}

.subheading {
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-200);
}
