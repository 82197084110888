.root {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  padding: 3rem 0;

}
@media (min-width: 990px) {
  .root {
    row-gap: 3rem;
    padding: 10rem 0 0;
  }
}
.title {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--neutral-400);
  letter-spacing: -0.02em;
  text-align: center;
}
@media (min-width: 990px) {
  .title {
    font-size: 2.5rem;
  }
}
.list {
  display: flex;
  gap: 0.5rem;
  margin: 0 1rem 0 -1rem;
  width: 100vw;
  padding-inline: 1rem;
  overflow-x: auto;
}
@media (min-width: 1200px) {
  .list {
    gap: 1rem;
    width: 100%;
    padding-inline: 0;
    overflow-x: inherit;
  }
}
.item {
  width: 33%;
  min-width: 19rem;
  min-height: 24.3rem;
  background-color: var(--neutral-white);
  padding: 1.5rem;
  border: 1px solid var(--brand-300);
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
}
@media (min-width: 990px) {
  .item {
    min-width: 20.5rem;
  }
}
.itemNumber {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: var(--brand-400);
  letter-spacing: -0.02em;
}
.itemTexts {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.itemTitle {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;
  color: var(--neutral-400);
  letter-spacing: -0.02em;
}
.itemDescription {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--neutral-200);
  letter-spacing: -0.01em;
}
