.root {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
}

.info {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-xxs);
}

.heading {
  font: var(--font-xl);
  letter-spacing: var(--font-xl-letter-spacing);
  color: var(--neutral-400);
}

.subheading {
  font: var(--font-l);
  letter-spacing: var(--font-l-letter-spacing);
  color: var(--neutral-200);
}
