.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 5rem;

  @media (min-width: 1024px) {
    padding: 0 1rem 0.5rem 0;
  }
}

.list {
  display: flex;
  flex-direction: column;
  padding-inline-start: 0;
  margin-block: 0;
  font: var(--font-s);
  letter-spacing: var(--font-s-letter-spacing);
  color: var(--neutral-300);
}

.item {
  display: grid;
  grid-template-columns: 1rem 1fr 3.5rem;
  align-items: center;
  column-gap: var(--space-s);
  padding: 0.25rem 0;
}

.time {
  text-align: right;
}
