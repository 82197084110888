.root {
  background: var(--brand-100);
}
.wrapper {
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
}
main {
  padding: 0 var(--space-s);
}
