.root {
  padding: 1rem 1rem 0;

  @media (min-width: 1024px) {
    padding-inline: 4vw;
  }
}

.inner {
  inline-size: min(100%, var(--max-width));
  margin-inline: auto;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 768px) {
    position: relative;
  }
}

.logo {
  display: flex;

  @media (min-width: 768px) {
    position: absolute;
    inset-inline-start: 50%;
    transform: translateX(-50%);
  }
}
