@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.root {
  max-width: 30rem;
  max-height: calc(100dvh - 2rem);
  inline-size: calc(100vw - 2rem);
  block-size: calc(100dvh - 2rem);
  padding-inline: 1.5rem;
  margin: 1rem;
  border: 0;
  border-radius: 1.25rem;
  inset: 0 0 0 auto;
  background-color: var(--brand-100);

  opacity: 0;
  transform: translateY(1rem);

  @media (min-width: 768px) {
    block-size: calc(100dvh - 3rem);
    margin: 1.5rem;
  }
}
.root[open] {
  animation: fadeIn var(--transition-normal) ease-in-out forwards;
}

.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  min-block-size: 100%;

  @media (min-width: 768px) {
    row-gap: 2.5rem;
  }
}

.header {
  display: flex;
  justify-content: flex-end;
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  inline-size: 2rem;
  block-size: 2rem;
  padding: 0;
  border: 0;
  background-color: transparent;
  opacity: 1;
  cursor: pointer;
  transition: opacity var(--transition-normal);

  &:hover,
  &:focus-visible {
    opacity: 0.7;
  }
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: var(--space-xxs);

  @media (min-width: 768px) {
    padding-inline: 1rem;
  }
}

.heading {
  font: var(--font-l);
  letter-spacing: var(--font-l-letter-spacing);
  color: var(--neutral-400);

  @media (min-width: 768px) {
    font: var(--font-xl);
    letter-spacing: var(--font-xl-letter-spacing);
  }
}

.subheading {
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-200);

  @media (min-width: 768px) {
    font: var(--font-l);
    letter-spacing: var(--font-l-letter-spacing);
  }
}

.form {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  row-gap: var(--space-s);
  padding-block-end: var(--space-l);
}
.form [class*='isFullWidth'] {
  inline-size: 100%;
}

.form [class*='form'] {
  flex-grow: 0;
}

.feedback {
  display: flex;
  align-items: center;
  column-gap: var(--space-s);
  padding: var(--space-s) var(--space-m);
  border: 1px solid var(--brand-300);
  border-radius: 1rem;
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-white);
  background-color: var(--feedback-color);
}

.error {
  --feedback-color: var(--status-error);
}

.success {
  --feedback-color: var(--status-success);
}
