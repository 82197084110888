.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
  inline-size: min(100%, 35rem);
  padding-inline: var(--space-s);
  margin-inline: auto;

  @media (min-width: 640px) {
    row-gap: 3rem;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5rem;
  text-align: center;
}

.heading {
  font: var(--font-xl);
  letter-spacing: var(--font-xl-letter-spacing);
  color: var(--neutral-400);
  text-align: center;
}

.subheading {
  inline-size: min(100%, 35.5rem);
  margin-inline: auto;
  font: var(--font-l);
  letter-spacing: var(--font-l-letter-spacing);
  color: var(--neutral-200);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  text-align: center;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  inline-size: min(100%, 35.5rem);
}

.records {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding-block-end: 1rem;
  border-block-end: 1px solid var(--neutral-100);

  @media (min-width: 640px) {
    padding-block-end: 1.5rem;
  }
}

.form {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
  flex: 1;
}

.formActions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  @media (min-width: 640px) {
    gap: 1.5rem;
  }
}

#facturas {
  display: none;
}

.headerContainer {
  text-align: center;
  margin-bottom: 0px;
}
