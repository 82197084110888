.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3rem;
  inline-size: 100%;
  padding-inline: var(--space-s);
  margin-inline: auto;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5rem;
  text-align: center;
}

.heading {
  inline-size: min(100%, 36.25rem);
  font: var(--font-xl);
  letter-spacing: var(--font-xl-letter-spacing);
  color: var(--neutral-400);
}

.subheading {
  inline-size: min(100%, 26.25rem);
  font: var(--font-l);
  color: var(--neutral-200);
  letter-spacing: var(--font-l-letter-spacing);
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;

  inline-size: 100%;
}

.form {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: var(--space-3xl);
  justify-content: space-between;
  align-items: center;
  inline-size: 100%;
}

.fieldset {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-2xl);
  margin: 0;
  padding: 0;
  inline-size: min(100%, 980px);
  border: 0;
}

.boxWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  gap: var(--space-xs) var(--space-s);
}

.box {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-l);
  padding: var(--space-l);
  border: 1px solid var(--brand-300);
  border-radius: 0.75rem;
  background-color: var(--neutral-white);
}

.boxHeader {
  display: flex;
  align-items: center;
  column-gap: var(--space-s);
  position: relative;

  @media (min-width: 768px) {
    column-gap: var(--space-l);
  }
}

.boxHeading {
  padding-inline-end: 2rem;
  font: var(--font-l);
  letter-spacing: var(--font-l-letter-spacing);
  text-wrap: balance;
  color: var(--neutral-400);
}

.boxHeaderButton {
  display: flex;
  align-items: center;
  column-gap: var(--space-xs);
  padding: 0;
  border: 0;
  position: absolute;
  inset-block-start: 50%;
  inset-inline-end: 0;
  transform: translateY(-50%);
  background-color: transparent;
  cursor: pointer;
}

.boxContent {
  input {
    border: 1px solid var(--brand-300);
  }
}

.button {
  align-self: center;
  padding: 0;
  border: 0;
  font: var(--font-m);
  text-decoration: none;
  color: var(--brand-400);
  background-color: transparent;
  cursor: pointer;
  transition: color var(--transition-normal);

  &:not(:disabled):hover,
  &:not(:disabled):focus-visible {
    color: var(--brand-400-active);
  }

  &:not(:disabled):focus-visible {
    outline: 2px solid var(--brand-400-active);
    outline-offset: 2px;
  }
}
