.root {
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
  inline-size: min(100%, 55rem);
  min-block-size: 100%;
  margin-inline: auto;
  padding: var(--space-l);
  border-radius: 1.25rem;
  background-color: var(--neutral-white);

  @media (min-width: 1024px) {
    padding: calc(var(--space-l) * 2);
  }
}

.title {
  font: var(--font-xl);
  letter-spacing: var(--font-xl-letter-spacing);
  color: var(--neutral-400);
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--space-l);

  :where(ul, ol) {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
    margin-block: 0;
    padding-inline-start: 1rem;
    list-style-position: outside;

    @media (min-width: 768px) {
      padding-inline-start: 2.5rem;
    }
  }

  :is(p, li) {
    font: var(--font-m);
    font-weight: 400;
    letter-spacing: var(--font-m-letter-spacing);
    color: var(--neutral-300);
  }
}
