@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.root {
  max-width: 30rem;
  max-height: calc(100dvh - 2rem);
  inline-size: calc(100vw - 2rem);
  block-size: calc(100dvh - 2rem);
  padding-inline: 1.5rem;
  margin: 1rem;
  border: 0;
  border-radius: 1.25rem;
  inset: 0 0 0 auto;
  background-color: var(--brand-100);

  opacity: 0;
  transform: translateY(1rem);

  @media (min-width: 768px) {
    block-size: calc(100dvh - 3rem);
    margin: 1.5rem;
  }
}

.root[open] {
  animation: fadeIn var(--transition-normal) ease-in-out forwards;
}

.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  min-block-size: 100%;

  @media (min-width: 768px) {
    row-gap: 2.5rem;
  }
}

.header {
  display: flex;
  justify-content: flex-end;
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-xxs);

  @media (min-width: 768px) {
    padding-inline: 1rem;
  }
}

.heading {
  font: var(--font-l);
  letter-spacing: var(--font-l-letter-spacing);
  color: var(--neutral-400);

  @media (min-width: 768px) {
    font: var(--font-xl);
    letter-spacing: var(--font-xl-letter-spacing);
  }
}

.subheading {
  font: var(--font-l);
  letter-spacing: var(--font-l-letter-spacing);
  color: var(--neutral-200);
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  inline-size: 2rem;
  block-size: 2rem;
  padding: 0;
  border: 0;
  background-color: transparent;
  opacity: 1;
  cursor: pointer;
  transition: opacity var(--transition-normal);

  &:hover,
  &:focus-visible {
    opacity: 0.7;
  }
}

.text {
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-200);
  white-space: pre-line;

  @media (min-width: 768px) {
    font: var(--font-l);
    letter-spacing: var(--font-l-letter-spacing);
  }
}

.footer {
  flex: 1;
  display: flex;
  align-items: flex-end;
  border-block-start: 1px solid var(--neutral-200);
}

.actions {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-s);
  inline-size: 100%;
}

.actions > *:last-of-type {
  color: var(--status-error);
}

/* NOTE: This is a workaround since the button and link atoms
    where not fully described in the UI Kit */
.actionLink > a {
  display: inline-flex;
  justify-content: space-between;
  column-gap: 0.75rem;
  inline-size: 100%;
  padding: var(--space-l);
  border-radius: 1.25rem;
  font: var(--font-m);
  text-align: center;
  text-decoration: none;
  color: var(--neutral-400);
  background-color: var(--neutral-white);
  transition: background-color var(--transition-normal);

  &:not(:disabled):hover,
  &:not(:disabled):focus-visible {
    background-color: var(--brand-200);
  }
}
