.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
  inline-size: min(100%, 35rem);
  margin-inline: auto;

  @media (min-width: 640px) {
    row-gap: 3rem;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5rem;
  padding-inline: var(--space-s);
  text-align: center;
}

.heading {
  font: var(--font-xl);
  letter-spacing: var(--font-xl-letter-spacing);
  color: var(--neutral-400);
}

.subheading {
  inline-size: min(100%, 35.5rem);
  margin-inline: auto;
  font: var(--font-l);
  letter-spacing: var(--font-l-letter-spacing);
  color: var(--neutral-200);
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  inline-size: 100%;
}

.optionsWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem 0.5rem;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding-block-start: var(--space-3xl);
  }
}

.summary {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-block: 0;
  padding-inline-start: 0;
}

.summaryItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-400);

  @media (min-width: 768px) {
    font: var(--font-l);
    letter-spacing: var(--font-l-letter-spacing);
  }
}

.summaryItemTotal {
  padding-block-start: var(--space-s);
  border-block-start: 1px solid var(--neutral-100);
}

.summaryItem span {
  color: var(--neutral-200);
}

.action {
  display: flex;
  justify-content: center;
  padding-inline: var(--space-s);
}
