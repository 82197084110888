.root {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-xs);
}

.alert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--space-l);
  padding: var(--space-s);
  border-radius: 1rem;
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-white);
  background: var(--alert-background, var(--neutral-200));
  transition: opacity var(--transition-normal) ease-out, transform var(--transition-normal) ease-out;

  @media (min-width: 768px) {
    padding: var(--space-s) var(--space-l);
  }
}

.isExiting {
  opacity: 0;
  transform: translateY(-1rem);
}

.high {
  --alert-background: var(--status-error);
}

.medium {
  --alert-background: var(--status-info);
}

.low {
  --alert-background: var(--status-success);
}

:is(.info, .actions) {
  display: flex;
  align-items: center;
  column-gap: var(--space-s);
}

:is(.close, .action) {
  display: flex;
  padding: 0;
  border: 0;
  cursor: pointer;
  color: inherit;
  background-color: transparent;
}

.action {
  text-decoration: none;
}
