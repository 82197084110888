.root {
  width: 100%;
  margin: 0 auto;
  padding: 3.75rem 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 1024px) {
  .root {
    max-width: 47.5rem;
    padding: 6.25rem 1rem;
  }
}
.boxIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  padding: 1rem 0 0.5rem;
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--neutral-400);
}
.separator {
  font-size: 1.65rem;
  font-weight: 100;
  color: var(--neutral-400);
}
.title {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: -0.02rem;
  color: var(--neutral-400);
  padding: 1rem 0.5rem 0.5rem;
}
@media (min-width: 768px) {
  .title {
    font-size: 2.5rem;
    letter-spacing: -0.07rem;
  }
}
.description {
  max-width: 25.7rem;
  margin: 0 auto;
  font: var(--font-m);
  font-weight: 500;
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-200);
  padding: 0.5rem 0.5rem 1rem;
}
@media (min-width: 768px) {
  .description {
    font: var(--font-lAlt);
    letter-spacing: var(--font-lAlt-letter-spacing);
  }
}
.graphicImage {
  padding: 3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image {
  width: 100%;
  height: 100%;
  max-width: 14.7rem;
}
@media (min-width: 768px) {
  .image {
    max-width: 21.43rem;
  }
}

