:root {
  --neutral-400: #181818;
  /* NOTE: This new color has been added when the UI was mature so its name is not following the naming convention */
  --neutral-350: #262626;
  --neutral-300: #606060;
  --neutral-250: #999999;
  --neutral-200: #a4a4a4;
  --neutral-100: #e4e4e4;
  --neutral-white: #ffffff;

  --overlay-100: rgba(24, 24, 24, 0.4);

  --brand-400-active: #ff6326;
  --brand-400: #fe4902;
  --brand-300: #d6e4e4;
  --brand-200: #e6ebeb;
  --brand-100: #f3f6f6;

  --status-error: #c4304d;
  --status-success: #16b25e;
  --status-info: #167eb2;
}

:root {
  --space-4xl: 6rem;
  --space-3xl: 4.5rem;
  --space-2xl: 2.5rem;
  --space-xl: 2rem;
  --space-l: 1.5rem;
  --space-m: 1.25rem;
  --space-s: 1rem;
  --space-xs: 0.5rem;
  --space-xxs: 0.25rem;
}

:root {
  --font-family-sans-serif: 'Plus Jakarta Sans', 'Roboto', 'Oxygen', sans-serif;

  --font-xl-bold: normal 700 2rem/1.19 var(--font-family-sans-serif);
  --font-xl-bold-letter-spacing: -2px;
  --font-xl-bold-feature-settings: 'ordn' on;

  --font-xl: normal 500 2rem/1.19 var(--font-family-sans-serif);
  --font-xl-letter-spacing: -1px;

  /* NOTE: This new style has been added when the UI was mature so its name is not following the naming convention */
  --font-lAlt: normal 600 1.5rem/1.3 var(--font-family-sans-serif);
  --font-lAlt-letter-spacing: -0.5px;

  --font-l: normal 600 1.25rem/1.3 var(--font-family-sans-serif);
  --font-l-letter-spacing: -0.5px;

  --font-m: normal 600 1rem/1.25 var(--font-family-sans-serif);
  --font-m-letter-spacing: -0.5px;

  --font-s: normal 600 0.875rem/1.43 var(--font-family-sans-serif);
  --font-s-letter-spacing: -0.1px;

  --font-xs: normal 500 0.75rem/1.33 var(--font-family-sans-serif);
  --font-xs-letter-spacing: -0.2px;
}

:root {
  --transition-normal: 300ms;
  --transition-fast: 200ms;
}

:root {
  --max-width: 82.5rem;
}

html {
  box-sizing: border-box;
  font-size: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  min-block-size: 100dvh;
  font: var(--font-m);
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--brand-100);
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
blockquote {
  margin: 0;
}

img,
picture,
video {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
  color: inherit;
}

a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
  }
}

#root {
  display: flex;
  flex-direction: column;
  min-block-size: 100dvh;
}

/*
  NOTE: Business decision to remove the outline from dialogs
  However, we want to keep the outline on hover for interactive elements at least on desktop
  for accessibility reasons
*/
:is(button, a, summary):not(:disabled):focus-visible {
  outline: none;

  @media (hover: hover) {
    outline: 2px solid var(--brand-400-active);
    outline-offset: 2px;
  }
}

:is(input[type='radio'], input[type='checkbox']):not(:disabled):focus-visible + label {
  outline: none;

  @media (hover: hover) {
    outline-color: var(--brand-400-active);
  }
}

:where(dialog):focus-visible {
  outline: none;
}

/* NOTE: Disable autofill for inputs */
:where(input, textarea, select) {
  &:is(:-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:focus, :autofill) {
    -webkit-box-shadow: 0 0 0px 1000px var(--neutral-white) inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

::backdrop {
  opacity: 1;
  background-color: var(--overlay-100);
  transition: all var(--transition-normal);
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
