.root {
  inline-size: min(100%, var(--max-width));
  margin-inline: auto;

  display: flex;
  flex-direction: column;
  row-gap: var(--space-s);

  @media (min-width: 1024px) {
    flex-direction: row;
    column-gap: var(--space-xl);
    block-size: 100%;
    padding: 1.25rem;
    border-radius: 1.25rem;
    outline: 1px solid var(--brand-300);
    outline-offset: -1px;
    background-color: var(--neutral-white);
  }
  @media (min-width: 1440px) {
    column-gap: var(--space-3xl);
  }
}

.panel {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-xl);
  justify-content: space-between;
  padding: 1rem;
  border: 1px solid var(--brand-300);
  border-radius: 1.25rem;
  background-color: var(--neutral-white);
  transition: all var(--transition-normal);

  @media (min-width: 1024px) {
    flex: 1;
    row-gap: var(--space-2xl);
    padding: 0;
    border-radius: 0;
    border: none;
    background-color: transparent;
  }
}

.legend {
  position: relative;

  @media (min-width: 1024px) {
    flex: 0 1 18.75rem;

    &::before {
      content: '';
      display: block;
      inline-size: 0.125rem;
      block-size: 100%;
      background-color: var(--brand-100);
      position: absolute;
      inset-inline-start: -1.25rem;
      z-index: 1;
    }
  }
  @media (min-width: 1440px) {
    &::before {
      inset-inline-start: -2.5rem;
    }
  }
}

.title {
  font: var(--font-l);
  letter-spacing: var(--font-l-letter-spacing);
  color: var(--neutral-400);
}

.tooltip {
  display: flex;
  justify-content: center;
  align-items: center;
  inline-size: 1.5rem;
  block-size: 1.5rem;
  padding: 0;
  border-radius: 50%;
  border: 0;
  background-color: var(--neutral-400);
  position: absolute;
  inset-block-start: 1.25rem;
  inset-inline-end: 1rem;
  cursor: pointer;

  @media (min-width: 1024px) {
    inset-block-start: 0.25rem;
    inset-inline-end: 1rem;
  }

  &[disabled] {
    background-color: var(--neutral-100);
    cursor: default;
  }
}

.icon {
  font: var(--font-m);
  color: var(--neutral-white);
}

.graphContent {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 8.5rem;

  /* NOTE: Safari 16 doesn't work as expected with `minmax` */
  grid-template-rows: 9rem;
  @supports (grid-template-rows: minmax(9rem, auto)) {
    grid-template-rows: minmax(9rem, auto);
  }

  column-gap: var(--space-l);
  align-items: flex-end;
  min-block-size: 11rem;
  max-block-size: 100%;
  position: relative;

  @media (min-width: 1440px) {
    column-gap: var(--space-3xl);
  }
}

.graphContent::before {
  content: '';
  position: absolute;
  inset-inline-start: -0.875rem;
  inline-size: 3rem;
  block-size: 100%;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, var(--neutral-white) 100%);
  pointer-events: none;
  z-index: 1;
  opacity: 0;
  transition: opacity 100ms;
}

.graphContent:has(div[data-has-scroll='true'])::before {
  opacity: 1;
}

.content {
  display: grid;
  column-gap: var(--space-l);
}
