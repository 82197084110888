.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
  inline-size: min(100%, 35rem);
  padding-inline: var(--space-s);
  margin-inline: auto;

  @media (min-width: 640px) {
    row-gap: 3rem;
  }
}

.heading {
  font: var(--font-xl);
  letter-spacing: var(--font-xl-letter-spacing);
  color: var(--neutral-400);
  text-align: center;
}

.content {
  flex: 1;
  display: flex;
  inline-size: min(100%, 26.5rem);
}

.fieldset {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-s);
  padding: 0;
  margin: 0;
  border: 0;
}

.fieldset + .fieldset {
  position: relative;
  margin-top: 1rem !important;
}

.fieldset + .fieldset::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  position: absolute;
  top: -2.5rem;
  background-color: var(--neutral-100);
}
