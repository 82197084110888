.form {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.formGroup {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: var(--space-l);
  padding-block-end: var(--space-l);
}

.fieldset {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-s);
  padding: 0;
  margin: 0;
  border: 0;
}

.fieldsetGrid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-areas:
    'x x x x x x'
    'c c c c c c'
    'n n s s s s'
    'f f f f l l'
    'p p p p p p'
    'z z z z z z';
  gap: 1rem 0.5rem;

  @media (min-width: 640px) {
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas:
      'x x x x x x x x x x x x'
      'c c c c c c c c c c c c'
      'n n s s s s s s f f f f'
      'l l l p p p p p z z z z';
  }
}
.fieldsetGrid > div:nth-of-type(1) {
  grid-area: x;
}

.fieldsetGrid > div:nth-of-type(2) {
  grid-area: c;
}

.fieldsetGrid > div:nth-of-type(3) {
  grid-area: n;
}

.fieldsetGrid > div:nth-of-type(4) {
  grid-area: s;
}

.fieldsetGrid > div:nth-of-type(5) {
  grid-area: f;
}

.fieldsetGrid > div:nth-of-type(6) {
  grid-area: l;
}

.fieldsetGrid > div:nth-of-type(7) {
  grid-area: p;
}

.fieldsetGrid > div:nth-of-type(8) {
  grid-area: z;
}

.legend {
  padding-inline: 0;
  margin-block-end: var(--space-xs);
  font: var(--font-xs);
  letter-spacing: var(--font-xs-letter-spacing);
  color: var(--neutral-300);
}

.formText {
  padding-block-start: var(--space-s);
  font: var(--font-m);
  color: var(--neutral-200);
  text-align: center;
}

.formText a {
  white-space: nowrap;
}

.formActions {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: var(--space-l);
}

.errorMsg {
  padding-inline-start: 1rem;
  font: var(--font-xs);
  color: var(--status-error);
}

.noPadding {
  padding-inline-start: 0;
}

.formRow {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: var(--space-l);
}

.passwordContainer {
  position: relative;
}

.togglePassword {
  inline-size: 1.25rem;
  block-size: 1.25rem;
  position: absolute;
  inset-inline-end: var(--space-s);
  inset-block-start: 0.875rem;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--neutral-350);
  padding: 0;
}
