.panel {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-l);
  padding: 1rem;
  border-radius: 1.25rem;
  background-color: var(--neutral-white);
  transition: all var(--transition-normal);

  @media (min-width: 768px) {
    padding: 1.5rem;
  }
}

.title {
  font: var(--font-l);
  letter-spacing: var(--font-l-letter-spacing);
  color: var(--neutral-400);
}

.graphContent {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: minmax(9rem, auto);
  /* NOTE: Safari 16 doesn't work as expected with `minmax` */
  grid-template-rows: 9rem;
  align-items: flex-end;
  column-gap: var(--space-l);
  min-block-size: 9rem;
  position: relative;

  @media (min-width: 768px) {
    grid-template-columns: 2fr 1fr;
  }
  @media (min-width: 1024px) {
    column-gap: var(--space-3xl);
  }
  @media (min-width: 1200px) {
    grid-template-columns: 752px 1fr;
  }
}

.graphContent::before {
  content: '';
  position: absolute;
  inset-inline-start: -0.875rem;
  inline-size: 3rem;
  block-size: 100%;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, var(--neutral-white) 100%);
  pointer-events: none;
  z-index: 1;
  opacity: 0;
  transition: opacity 100ms;
}

.graphContent:has(div[data-has-scroll='true'])::before {
  opacity: 1;
}
