.root {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  gap: 1.5rem;
  position: relative;

  @media (min-width: 640px) {
    gap: 2rem;
  }
}

.header {
  padding: 1rem 1rem 0;

  @media (min-width: 1024px) {
    padding-inline: 4vw 0;
  }
}

.inner {
  display: flex;
  align-items: center;
  column-gap: var(--space-l);
  inline-size: min(100%, var(--max-width));
  margin-inline: auto;
}

.backButton {
  display: flex;
  align-items: center;
  padding: 2px 0;
  border: 0;
  color: var(--brand-400);
  white-space: nowrap;
  background-color: transparent;
  cursor: pointer;
}

.backgButton > svg {
  min-inline-size: 2.5rem;
  min-block-size: 2.5rem;
}

.title {
  @media (max-width: 768px) {
    display: none;
  }
}

.logo {
  inline-size: 97px;
  position: absolute;
  inset-inline-start: 50%;
  transform: translateX(-50%);
}

.content {
  flex: 1;
  display: flex;
  padding-inline: 1rem;

  @media (min-width: 1024px) {
    padding-inline: 4vw;
  }
}

.wrapper {
  inline-size: min(100%, var(--max-width));
  margin-inline: auto;
}
