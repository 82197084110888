.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-column: 1 / -1;
  row-gap: var(--space-l);
}

.root > svg {
  display: flex;
}

.root > p {
  inline-size: min(100%, 20rem);
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  text-align: center;
  color: var(--neutral-300);
}

.isAbsolute {
  inline-size: calc(100% + 2rem);
  block-size: 100%;
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  z-index: 1;
  background-color: #ffffffb8;
  transform: translate(-50%, -50%);
}
