.root {
  inline-size: min(100%, 90rem);
  margin-inline: auto;

  display: flex;
  flex-direction: column;
  min-height: 100vh;

  row-gap: var(--space-l);
  padding: var(--space-l) var(--space-s) var(--space-2xl);

  @media (min-width: 768px) {
    row-gap: var(--space-xl);
    padding-block: var(--space-xl) var(--space-4xl);
  }
  @media (min-width: 1024px) {
    row-gap: var(--space-2xl);
    padding-inline: 4vw;
  }
}

.header {
  display: flex;
  align-items: center;
  column-gap: var(--space-xs);
  inline-size: min(100%, 90rem);
  margin-inline: auto;
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-400);
}
.header > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.backButton {
  display: flex;
  align-items: center;
  column-gap: var(--space-l);
  padding: 0;
  border: 0;
  color: var(--brand-400);
  white-space: nowrap;
  background-color: transparent;
  cursor: pointer;
}

.backButton > svg {
  min-inline-size: 2.5rem;
  min-block-size: 2.5rem;
}

.content {
  inline-size: min(100%, 90rem);
  margin-inline: auto;

  flex: 1;
  display: flex;
}
