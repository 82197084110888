@keyframes s3 {
  100% {
    transform: rotate(1turn);
  }
}

.loader {
  inline-size: 3rem;
  block-size: 3rem;
  border-radius: 50%;
  margin: 5rem auto;
  background: radial-gradient(farthest-side, var(--brand-400) 94%, #0000) top/4px 4px no-repeat,
    conic-gradient(#0000 30%, var(--brand-400));
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  animation: s3 1s infinite linear;
}
