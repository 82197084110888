.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3.5rem;
  inline-size: min(100%, 35rem);
  margin-inline: auto;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5rem;
  text-align: center;
}

.heading {
  font: var(--font-xl);
  letter-spacing: var(--font-xl-letter-spacing);
  color: var(--neutral-400);
}

.subheading {
  font: var(--font-l);
  letter-spacing: var(--font-l-letter-spacing);
  color: var(--neutral-200);
}

.content {
  flex: 1;
  display: flex;
  inline-size: min(100%, 20.5rem);
}
