.root {
  flex-grow: 1;
  padding-inline: 1rem;

  @media (min-width: 1024px) {
    padding-inline: 4vw;
  }
}

.inner {
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
  inline-size: min(100%, var(--max-width));
  margin-inline: auto;
}

.switcher {
  display: flex;
  column-gap: var(--space-xxs);
  padding: 0.25rem;
  border-radius: 0.625rem;
  background-color: var(--neutral-white);
  position: fixed;
  z-index: 1;
  inset-inline-start: 50%;
  inset-block-end: 3rem;
  transform: translateX(-50%);
  box-shadow: 0px 4px 40px 0px #00000026;
}

.switcherButton {
  display: flex;
  column-gap: var(--space-xs);
  align-items: center;
  padding: 0.625rem;
  border: 0;
  border-radius: 0.625rem;
  font: var(--font-s);
  letter-spacing: var(--font-s-letter-spacing);
  color: var(--neutral-300);
  background-color: var(--neutral-white);
  transition: all var(--transition-normal) ease-in-out;
  cursor: pointer;

  &:hover,
  &:focus-visible {
    background-color: var(--brand-100);
  }
}

.switcherButton.isActive {
  background-color: var(--brand-400);
  color: var(--neutral-white);
}
