.option {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 0.75rem;
  min-inline-size: 16.75rem;
  padding: 2rem;
  border: 2px solid transparent;
  border-radius: 1rem;
  background-color: var(--neutral-white);
  cursor: pointer;
  transition: border-color var(--transition-normal);

  &:hover,
  &:focus-within {
    border-color: var(--brand-300);
  }
}

.option.isSelected {
  border-color: var(--brand-300);
}

.input {
  border: 0px;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0px;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
}

.optionHeading {
  /* NOTE: This font-weight is not in the UI Kit */
  font: var(--font-l);
  font-weight: 600;
  letter-spacing: var(--font-l-letter-spacing);
  color: var(--neutral-200);
}

.optionPrice {
  /* NOTE: This font-weight is not in the UI Kit */
  font: var(--font-xl);
  font-weight: 600;
  letter-spacing: var(--font-xl-letter-spacing);
  color: var(--neutral-400);
}

.optionPriceYearly {
  font: var(--font-m);
  letter-spacing: var(--font-m-letter-spacing);
  color: var(--neutral-200);
}
