.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3.5rem;
  inline-size: min(100%, 50rem);
  margin-inline: auto;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5rem;
  text-align: center;
}

.heading {
  font: var(--font-xl);
  letter-spacing: var(--font-xl-letter-spacing);
  color: var(--neutral-400);
}

.controlButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem 0.5rem;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
}

.content {
  inline-size: 100%;
}

.controlButton {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 2.5rem 2rem;
  inline-size: 100%;
  inline-size: min(100%, 24.5rem);
  padding: 1.5rem;
  border: 1px solid var(--brand-300);
  border-radius: 0.75rem;
  background-color: var(--neutral-white);
  cursor: pointer;
  transition: background-color var(--transition-normal);

  @media (min-width: 768px) {
    flex-direction: column;
    padding-block: 3rem;
    border-radius: 1.25rem;
  }

  &:hover,
  &:focus-within {
    background-color: var(--brand-100);
  }
}

.controlButton > img {
  --icon-size: 4rem;
  min-inline-size: var(--icon-size);
  max-inline-size: var(--icon-size);
  min-block-size: var(--icon-size);
  max-block-size: var(--icon-size);

  @media (min-width: 768px) {
    --icon-size: 7rem;
  }
}

.controlButtonText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;

  @media (min-width: 768px) {
    align-items: center;
    row-gap: 0.5rem;
  }
}

.controlButtonText strong {
  font: var(--font-l);
  letter-spacing: var(--font-l-letter-spacing);
  color: var(--neutral-400);

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
}

.controlButtonText span {
  font: var(--font-s);
  letter-spacing: var(--font-s-letter-spacing);
  color: var(--neutral-200);

  @media (min-width: 768px) {
    font: var(--font-m);
    letter-spacing: var(--font-m-letter-spacing);
  }
}
