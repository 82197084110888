.root {
  padding: 0 1rem 1.5rem;
  margin-block-start: auto;

  @media (min-width: 1024px) {
    padding-inline: 4vw;
  }
  @media (min-height: 850px) {
    margin-block-start: var(--space-l);
  }
}

.root a {
  font: var(--font-s);
  letter-spacing: var(--font-s-letter-spacing);
  color: var(--neutral-200);

  &:not(:disabled):hover,
  &:not(:disabled):focus-visible {
    color: var(--neutral-300);
  }
}

.inner {
  inline-size: min(100%, var(--max-width));
  margin-inline: auto;

  display: flex;
  flex-direction: column;
  row-gap: var(--space-l);
  padding-block-start: var(--space-l);
  border-block-start: 1px solid var(--neutral-200);

  @media (min-width: 768px) {
    flex-direction: row;

    justify-content: space-between;
    padding-block-start: 0;
    border-block-start: none;
  }
}

.menu,
.contact {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-l);

  @media (min-width: 768px) {
    flex-direction: row;
    column-gap: var(--space-2xl);
  }
}
